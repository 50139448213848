<template>
  <header>
    <div class="wrapper">
      <img @click="reloadPage" class="header-logo" alt="Itbridge" src="../assets/logo5.png">
      <div class="links">
        <a href="#achievements"> {{$t("about company")}}</a>
        <a href="#our-services"> {{$t("our services")}}</a>
        <a href="#contacts"> {{$t("contacts")}}</a>

        <div class="language-switch">
          <select class="language-select" v-model="$i18n.locale">
            <option value="en">EN</option>
            <option value="rs">SR</option>
          </select>
        </div>

      </div>
      <burger-menu @toggle-menu="menuActive = !menuActive" :active="menuActive"></burger-menu>
    </div>
    <div id="navbarNav" v-show="menuActive">
      <div class="navbar-menu" >
        <a @click="menuActive = false" href="#achievements"> {{$t("about company")}}</a>
        <a @click="menuActive = false" href="#our-services"> {{$t("our services")}}</a>
        <a @click="menuActive = false" href="#contacts"> {{$t("contacts")}}</a>
        <div class="language-switch" style="width: 100%; margin-left: 0" >
          <select style="width: 50%;" class="language-select" v-model="$i18n.locale">
            <option value="en">EN</option>
            <option value="rs">SR</option>
          </select>
        </div>
      </div>

    </div>

  </header>
</template>

<script>
import BurgerMenu from "./BurgerMenu";

export default {
  name: "Header",
  components: {
    BurgerMenu
  },
  data() {
    return {
      menuActive: false
    }
  },
  methods: {
    setLocale(locale) {
      console.log(locale)
      this.$i18n.locale = locale
    },
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
@import './src/style/style.scss';
header {
  background: $base-color;
  //position: sticky;
}
.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px var(--global-sides-margin);
}
.header-logo {
  width: 220px;
  cursor: pointer;
}
.links {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  a {
    font-size: 18px;
    color: white;
    text-decoration: none;
    height: fit-content;
  }
}

.language-switch {
  border: none;
  background-color: transparent;
  margin-left: 60px;
}
.language-select {
  //--arrow-bg: rgba(255, 255, 255, 1);
  --arrow-bg: transparent;
  --option-bg: white;
  //--select-bg: rgba(255, 255, 255, 1);
  --select-bg: transparent;
  color: white;
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 60px;
  padding: 1rem 1rem 1rem 1rem;
  background:
  linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  //color: black;
  text-align: center;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}

.navbar-menu {
  z-index: 100;
  display:flex;
  flex-direction: column;
  gap: 32px;
  color: white;
}

.navbar-menu a {
  font-size: 32px;
  color: white;
  text-decoration: none;
}
#navbarNav {
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


@media only screen and (max-width: 991px) {
  .links{
    display: none;
  }
  .links {
    margin-left: 30px;
  }


  #navbarNav {
    display: block;
    margin-left: 0;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
  }
}
@media  screen and (max-width: 500px) {
  .text-main {
    font-size: 52px;
  }
  .header-logo {
    //width: 120px;
  }
  .links {
    margin-left: 0;
  }
}

</style>
