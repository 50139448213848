<template>
  <div class="wrapper">
    <div class="request-form" id="request-form">
      <span class="text">Submit a request for consultation</span>
      <div class="display-row">
        <input placeholder="Name*" type="text" class="input-name" >
        <input placeholder="+381 xx xxxx xxx*" type="tel" class="input-tel" >
        <input placeholder="E-mail*" type="email" class="input-email">
        <input placeholder="Company" type="text" class="input-company">
      </div>
      <textarea placeholder="Comments" ></textarea>
      <button>Send a request</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestForm"
}
</script>

<style scoped lang="scss">
@import './src/style/style.scss';

.wrapper {
  background-color: #110551;
  padding: 30px var(--global-sides-margin);
}
.request-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: white;
  border-radius: 40px;
  padding: 30px 60px;
}
.text {
  font-size: 50px;
  color: #110551;
}
input, textarea {
  background-color: #DDD8E4;
  font-size: 20px;
  color: #110551;
  padding: 20px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
}
textarea {
  resize: vertical;
  max-height: 200px;
}
input::placeholder, textarea::placeholder {
  color: #110551;
  opacity: 1; /* Firefox */
}
.display-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input-tel  {
  width: 30%
}
.input-company, .input-email, .input-name{
  width: 20%
}
button {
  //width: 20%;
  height: 60px;
  border-radius: 40px;
  padding: 20px 0;
  background: #0af886;
  color: white;
  border: none;
  font-size: 20px;
  font-weight: 600;
}
@media  screen and (max-width: 991px) {
  .text {
    font-size: 28px;
  }
  .request-form {
    padding: 30px 30px;
  }
  .display-row {
    flex-direction: column;
  }
  .input-tel, .input-company, .input-email, .input-name{
    width: 100%
  }
  textarea {
    height: 320px;
  }
}
</style>
