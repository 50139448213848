<template>
  <div class="wrapper">

    <p class="whyus-text"> Why choose us?</p>
    <div class="qualities">
      <div class="qualities-column">
        <div class="quality quality-left">
          <img src="../assets/icons/icon1.svg">
          <span>We work with the <strong>government</strong> and <strong>corporate</strong> sector</span>
        </div>
        <div class="quality quality-left">
          <img src="../assets/icons/icon3.svg">
          <span>Support, maintenance and modernization</span>
        </div>
        <div class="quality quality-left">
          <img src="../assets/icons/icon5.svg">
          <span>Design, installation, commissioning and testing</span>
        </div>

      </div>
      <div class="qualities-column">
        <img class="qualities-image" src="../assets/img.png">

      </div>
      <div class="qualities-column qualities-column-right">
        <div class="quality quality-right">
          <span>We <strong>automate</strong> technological and business processes</span>
          <img src="../assets/icons/icon2.svg">

        </div>
        <div class="quality quality-right">
          <span>Supply and use of equipment, software</span>
          <img src="../assets/icons/icon4.svg">

        </div>
        <div class="quality quality-right">
          <span><strong>Warranty</strong> and post-warranty service</span>
          <img src="../assets/icons/icon6.svg">

        </div>

      </div>

      </div>
  </div>

</template>

<script>
export default {
  name: "WhyUs"
}
</script>

<style scoped lang="scss">
@import './src/style/style.scss';

.wrapper {
  background-color: #f5f8ff;
  padding: 30px var(--global-sides-margin);
}
.whyus-text {
  font-size: 60px;
  color: #110551;
  font-weight: 800;
}
.qualities {
  display: flex;
  flex-direction: row;
  min-height: 400px;
  padding: 20px 0 30px 0;
}
@media  screen and (max-width: 991px) {
  .qualities {
    flex-direction: column;

  }
  .qualities-image {
    display: none;
  }
  .qualities-column-right {
    margin-top: 50px;
  }
  .quality-right {
    text-align: left !important;
    flex-direction: row-reverse !important;

  }
  .whyus-text {
    font-size: 41px;
  }
}
.qualities-column {
  display: flex;
  flex-direction: column;
  gap: 50px;
  color: #110551;
}
.quality {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}
.quality img {
  width: 12%;
}
.quality-left {
  text-align: left;
}
.quality-right {
  text-align: right;
  justify-content: flex-end;
}
.qualities-image {
  align-self: center;
  width: 300px;
}

</style>
